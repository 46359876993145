import React, { useEffect } from "react"
import AboutUs from "../components/About"
import Layout from "../components/Layout/layout"
import Services from "../components/Services"
import Marquee from "../components/Marquee"

import SEO from "../components/seo"
import RelateProduct from "../components/Products/RelateProduct"
import useGatsbyStripeData from "../components/hooks/useGatsbyStripeData"
import Loading from "../components/Loading"

const About = ({ location }) => {
  const siteTitle = "ÜBER UNS"
  const data = useGatsbyStripeData()
  const handleScroll = e => {
    const siteHeader = document.querySelector(".site-header")
    const linkItem = document.querySelectorAll(".header-link__item")
    const element = e.target.firstElementChild
    if (element.scrollTop > 0) {
      // siteHeader.style.boxShadow = "0 0 1px 1px #cecece"
      siteHeader.style.background = "black"
      ;[...linkItem].forEach(item => (item.style.color = "white"))
    } else {
      siteHeader.style.background = "unset"
      // ;[...linkItem].forEach(item => (item.style.color = "black"))
      // siteHeader.style.boxShadow = "unset"
    }
  }

  useEffect(() => {
    const siteHeader = document.querySelector(".site-header")
    siteHeader.style.background = "black"

    // window.addEventListener("scroll", handleScroll)
    // return () => {
    //   const siteHeader = document.querySelector(".site-header")

    //   siteHeader.style.background = "black"
    //   window.removeEventListener("scroll", handleScroll)
    // }
  }, [])
  return (
    <Layout>
      <SEO title="Schnelle Reparatur" />
      <Services location={location} />
      <div className="product-related wrap-page">
        <RelateProduct data={data} />
      </div>
    </Layout>
  )
}

export default About
